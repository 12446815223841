import React from 'react';
import { Illustration } from './Illustration';
import { Link } from 'react-router-dom';

const TrustedBy = () => (
  <div className="mt-8">
    <p className="text-sm text-gray-500 mb-4">Integrates with leading veterinary software</p>
    <div className="flex flex-wrap items-center justify-center lg:justify-start gap-8">
      <img 
        src="/pulse-logo.png" 
        alt="Pulse" 
        className="h-10 lg:h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200"
      />
      <img 
        src="/impromed-logo.png" 
        alt="Impromed" 
        className="h-10 lg:h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200"
      />
      <img 
        src="/avimark-logo.png" 
        alt="Avimark" 
        className="h-10 lg:h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200"
      />
    </div>
  </div>
);

export const Hero = () => {
  return (
    <div className="w-[90%] max-w-7xl mx-auto px-4 sm:px-6 pt-20 pb-2">
      <div className="flex flex-col lg:flex-row items-center gap-12">
        <div className="flex-1 text-center lg:text-left">
          <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold leading-[1.2] lg:leading-[1.1] tracking-tight">
            Organize.
            <br />
            Streamline.
            <br />
            Care.
          </h1>
          <p className="text-base sm:text-lg text-gray-600 mt-6 mb-8 max-w-3xl break-words">
            Your space to streamline veterinary work and enhance patient care.
          </p>
          <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-3 w-[60%] sm:w-auto mx-auto lg:mx-0">
            <a 
              href="https://calendly.com/admin-lyravet/demo"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#00B8D4] text-white px-6 py-2 rounded-lg hover:bg-[#008CA1] transition-colors text-base font-medium min-w-[160px] text-center"
            >
              Request a demo
            </a>
            <Link 
              to="/signup"
              className="border-2 border-[#00B8D4] text-[#00B8D4] px-6 py-2 rounded-lg hover:bg-[#00B8D4] hover:text-white transition-colors text-base font-medium min-w-[160px] text-center"
            >
              Start Free Trial
            </Link>
          </div>
          <TrustedBy />
        </div>
        <div className="flex-1 scale-150 mr-[30px] overflow-visible hidden lg:block">
          <Illustration />
        </div>
      </div>
    </div>
  );
};