import React from 'react';
import { Clock, MessageSquare, FileText, ListChecks } from 'lucide-react';

const PatientBrief = ({ name, condition, insight }) => (
  <div className="bg-white rounded-lg p-4 shadow-sm border border-cosmic-cyan/20">
    <h3 className="text-lg font-semibold text-deep-space mb-2">{name}</h3>
    <p className="text-gray-600 text-sm mb-3">{condition}</p>
    <div className="flex items-center gap-2 text-cosmic-cyan italic text-sm">
      <div className="w-4 h-4">💡</div>
      {insight}
    </div>
  </div>
);

export const FeatureCards = () => {
  const features = [
    {
      icon: <Clock className="h-8 w-8 text-cosmic-cyan" />,
      title: "Mission Brief",
      description: "Begin each day with confidence using AI-powered patient summaries. Get concise, clinically relevant information for all scheduled appointments, allowing you to focus on delivering excellent care without sifting through extensive records.",
      illustration: (
        <div className="space-y-4 overflow-y-auto max-h-[400px] p-4 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
          <PatientBrief
            name="Henry"
            condition="Henry has been experiencing anxiety at home, characterized by pacing, whining, and fidgeting behaviors. Trazodone was recommended over fluoxetine to manage these symptoms..."
            insight="Henry goes to daycare once a week. His owners mentioned that he loves to watch movies that have dogs in them."
          />
          <PatientBrief
            name="Katie"
            condition="Katie has been struggling with chronic health issues over the past year, including weight loss, intermittent diarrhea, and vomiting. Diagnosed with suspected chronic pancreatitis..."
            insight="Katie loves giving high fives with both paws, especially when treats are involved!"
          />
          <PatientBrief
            name="Indie"
            condition="Indie has a history of mild dental tartar noted during a general exam in January 2024. In March..."
            insight="Indie the cat got her name from Indiana Jones - she's just as adventurous, but prefers chasing toy mice over ancient artifacts!"
          />
        </div>
      ),
      highlights: [
        "At-a-glance patient overviews",
        "Automated clinical summaries",
        "Prioritized health concerns"
      ]
    },
    {
      icon: <FileText className="h-8 w-8 text-cosmic-cyan" />,
      title: "Medical Scribe",
      description: "Transform your appointments into thorough medical notes effortlessly. Whether you prefer voice recording or quick bullet points, our AI scribe handles the heavy lifting, ensuring you spend less time on paperwork and more time with patients.",
      illustration: "/scribe-output.png",
      highlights: [
        "Voice-to-SOAP conversion",
        "Bullet point expansion",
        "Automated medical formatting"
      ]
    },
    {
      icon: <MessageSquare className="h-8 w-8 text-cosmic-cyan" />,
      title: "Chat Support",
      description: "Unlock the full potential of your patient's medical history with AI-powered insights. Quickly retrieve specific information, track symptom progression, and refine care plans through natural conversation with your patient's complete history.",
      illustration: (
        <div className="space-y-4 overflow-y-auto max-h-[400px] p-4 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
          {/* User Message */}
          <div className="flex justify-end">
            <div className="bg-cosmic-cyan text-white rounded-2xl rounded-tr-sm p-4 max-w-[80%]">
              <p className="text-sm">Please give me differentials for a dog with intermittent PU/PD?</p>
            </div>
          </div>
          
          {/* Bot Message */}
          <div className="flex justify-start">
            <div className="bg-white rounded-2xl rounded-tl-sm p-4 max-w-[80%] shadow-sm border border-cosmic-cyan/20">
              <p className="text-sm mb-3">Certainly. Here are some key differentials for a dog presenting with intermittent polyuria and polydipsia (PU/PD):</p>
              
              <div className="text-sm space-y-3">
                <div>
                  <p className="font-medium">1. Endocrine disorders:</p>
                  <ul className="list-disc pl-5">
                    <li>Hyperadrenocorticism (Cushing's disease)</li>
                    <li>Diabetes mellitus</li>
                    <li>Hypoadrenocorticism (Addison's disease)</li>
                    <li>Hyperthyroidism (rare in dogs)</li>
                  </ul>
                </div>

                <div>
                  <p className="font-medium">2. Renal disorders:</p>
                  <ul className="list-disc pl-5">
                    <li>Chronic kidney disease</li>
                    <li>Acute kidney injury</li>
                    <li>Pyelonephritis</li>
                  </ul>
                </div>

                <div>
                  <p className="font-medium">3. Hepatic disorders:</p>
                  <ul className="list-disc pl-5">
                    <li>Hepatic insufficiency</li>
                    <li>Portosystemic shunt</li>
                  </ul>
                </div>

                <div>
                  <p className="font-medium">4. Electrolyte imbalances:</p>
                  <ul className="list-disc pl-5">
                    <li>Hypercalcemia</li>
                    <li>Hypokalemia</li>
                  </ul>
                </div>

                <p className="font-medium">5. Urinary tract infections</p>
                <p className="font-medium">6. Psychogenic polydipsia</p>

                <div>
                  <p className="font-medium">7. Iatrogenic causes:</p>
                  <ul className="list-disc pl-5">
                    <li>Glucocorticoid administration</li>
                    <li>Diuretic therapy</li>
                  </ul>
                </div>

                <p className="font-medium">8. Hypoglycemia (reactive PU/PD)</p>
                <p className="font-medium">9. Central diabetes insipidus</p>
                <p className="font-medium">10. Nephrogenic diabetes insipidus</p>

                <p className="mt-4 italic">It's important to note that the intermittent nature of the PU/PD may suggest certain differentials over others, and a thorough history, physical examination, and appropriate diagnostic tests are crucial for accurate diagnosis.</p>

                <p className="mt-4 text-xs text-gray-500">Citation: Ettinger, S.J., Feldman, E.C., & Côté, E. (2017). Textbook of Veterinary Internal Medicine: Diseases of the Dog and the Cat (8th ed.). Elsevier. Chapter 84: Polyuria and Polydipsia.</p>
              </div>
            </div>
          </div>

          {/* User Message */}
          <div className="flex justify-end">
            <div className="bg-cosmic-cyan text-white rounded-2xl rounded-tr-sm p-4 max-w-[80%]">
              <p className="text-sm">His bloodwork is normal with a USG of 1.010. He has proteinuria on dipstick. What would be good next diagnostics?</p>
            </div>
          </div>

          {/* Bot Message */}
          <div className="flex justify-start">
            <div className="bg-white rounded-2xl rounded-tl-sm p-4 max-w-[80%] shadow-sm border border-cosmic-cyan/20">
              <p className="text-sm mb-3">Given the information provided, including normal bloodwork, a urine specific gravity (USG) of 1.010, and proteinuria on dipstick, the following diagnostics would be appropriate next steps:</p>
              
              <div className="text-sm space-y-3">
                <div>
                  <p className="font-medium">1. Urine Protein:Creatinine Ratio (UPC):</p>
                  <p className="pl-5">To quantify and confirm the degree of proteinuria, as dipstick results can be affected by urine concentration.</p>
                </div>

                <div>
                  <p className="font-medium">2. Complete Urinalysis with Sediment Examination:</p>
                  <p className="pl-5">To evaluate for presence of cells, casts, crystals, or bacteria that might indicate underlying urinary tract disease.</p>
                </div>

                <div>
                  <p className="font-medium">3. Urine Culture and Sensitivity:</p>
                  <p className="pl-5">To rule out urinary tract infection, which can cause proteinuria and dilute urine.</p>
                </div>

                <div>
                  <p className="font-medium">4. Abdominal Ultrasound:</p>
                  <p className="pl-5">To assess kidney architecture, size, and echogenicity, as well as to evaluate for urinary bladder abnormalities or other abdominal pathologies.</p>
                </div>

                <div>
                  <p className="font-medium">5. Blood Pressure Measurement:</p>
                  <p className="pl-5">Hypertension can cause proteinuria and is associated with various underlying conditions.</p>
                </div>

                <div>
                  <p className="font-medium">6. Symmetric Dimethylarginine (SDMA):</p>
                  <p className="pl-5">A more sensitive marker of early kidney dysfunction than creatinine.</p>
                </div>

                <div>
                  <p className="font-medium">7. Adrenocorticotropic Hormone (ACTH) Stimulation Test or Low-Dose Dexamethasone Suppression Test:</p>
                  <p className="pl-5">To rule out hyperadrenocorticism, which can cause PU/PD and proteinuria.</p>
                </div>

                <div>
                  <p className="font-medium">8. Antinuclear Antibody (ANA) Test:</p>
                  <p className="pl-5">If immune-mediated disease is suspected as a cause of proteinuria.</p>
                </div>

                <div>
                  <p className="font-medium">9. Imaging Studies:</p>
                  <p className="pl-5">Thoracic radiographs to check for evidence of metastatic disease or other systemic illness.</p>
                </div>

                <p className="mt-4">These diagnostics will help further characterize the proteinuria, assess kidney function, and potentially identify underlying causes of the intermittent PU/PD.</p>

                <div className="mt-4 space-y-2 text-xs text-gray-500">
                  <p>Citations:</p>
                  <p>Littman, M.P. (2011). Protein-losing Nephropathy in Small Animals. Veterinary Clinics of North America: Small Animal Practice, 41(1), 31-62.</p>
                  <p>Grauer, G.F. (2011). Proteinuria: Measurement and Interpretation. Topics in Companion Animal Medicine, 26(3), 121-127.</p>
                  <p>Polzin, D.J. (2017). Chronic Kidney Disease. In S.J. Ettinger, E.C. Feldman, & E. Côté (Eds.), Textbook of Veterinary Internal Medicine (8th ed., pp. 4693-4734). Elsevier.</p>
                </div>
              </div>
            </div>
          </div>

          {/* User Message */}
          <div className="flex justify-end">
            <div className="bg-cosmic-cyan text-white rounded-2xl rounded-tr-sm p-4 max-w-[80%]">
              <p className="text-sm">Can you explain how the differentials would change if the UPC ratio was very high, mildly elevated, and normal?</p>
            </div>
          </div>

          {/* Bot Message */}
          <div className="flex justify-start">
            <div className="bg-white rounded-2xl rounded-tl-sm p-4 max-w-[80%] shadow-sm border border-cosmic-cyan/20">
              <p className="text-sm mb-3">Certainly. The Urine Protein:Creatinine (UPC) ratio is an important diagnostic tool that helps quantify proteinuria and can guide the differential diagnosis. I'll explain how the differentials might change based on different UPC ratio results:</p>
              
              <div className="text-sm space-y-3">
                <div>
                  <p className="font-medium">1. Normal UPC ratio (&lt; 0.2 in dogs):</p>
                  <p className="pl-5 mb-2">If the UPC ratio is normal despite the positive dipstick result, differentials would focus more on:</p>
                  <ul className="list-disc pl-10">
                    <li>Prerenal causes of PU/PD (e.g., psychogenic polydipsia, central diabetes insipidus)</li>
                    <li>Endocrine disorders (e.g., hyperadrenocorticism, diabetes mellitus)</li>
                    <li>Hepatic disorders</li>
                    <li>Electrolyte imbalances</li>
                  </ul>
                </div>

                <div>
                  <p className="font-medium">2. Mildly elevated UPC ratio (0.2-2.0 in dogs):</p>
                  <p className="pl-5 mb-2">A mildly elevated UPC ratio may indicate:</p>
                  <ul className="list-disc pl-10">
                    <li>Early stages of glomerular disease</li>
                    <li>Tubular dysfunction</li>
                    <li>Urinary tract inflammation or infection</li>
                    <li>Hypertension</li>
                    <li>Early stages of some systemic diseases</li>
                  </ul>
                </div>

                <div>
                  <p className="font-medium">3. Very high UPC ratio (2.0+ in dogs):</p>
                  <p className="pl-5 mb-2">A significantly elevated UPC ratio strongly suggests glomerular disease. Differentials would focus on:</p>
                  <ul className="list-disc pl-10">
                    <li>Immune-complex glomerulonephritis</li>
                    <li>Amyloidosis</li>
                    <li>Hereditary nephropathies</li>
                    <li>Chronic kidney disease with significant glomerular damage</li>
                    <li>Systemic diseases with renal involvement (e.g., systemic lupus erythematosus, Lyme disease)</li>
                    <li>Neoplasia (particularly lymphoma)</li>
                    <li>Infectious diseases (e.g., leishmaniasis, ehrlichiosis)</li>
                  </ul>
                </div>

                <p className="mt-4 italic">It's important to note that the UPC ratio should be interpreted in conjunction with other clinical findings, urinalysis results, and patient history. The persistence of proteinuria should also be confirmed with repeated measurements.</p>

                <div className="mt-4 space-y-2 text-xs text-gray-500">
                  <p>Citations:</p>
                  <p>Grauer, G.F. (2011). Proteinuria: Measurement and Interpretation. Topics in Companion Animal Medicine, 26(3), 121-127.</p>
                  <p>Littman, M.P., Daminet, S., Grauer, G.F., Lees, G.E., & van Dongen, A.M. (2013). Consensus Recommendations for the Diagnostic Investigation of Dogs with Suspected Glomerular Disease. Journal of Veterinary Internal Medicine, 27(S1), S19-S26.</p>
                  <p>Harley, L., & Langston, C. (2012). Proteinuria in dogs and cats. The Canadian Veterinary Journal, 53(6), 631-638.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      highlights: [
        "Intelligent history analysis",
        "Lab result comparisons",
        "Treatment timeline tracking"
      ]
    },
    {
      icon: <ListChecks className="h-8 w-8 text-cosmic-cyan" />,
      title: "Task Checklist",
      description: "Experience the perfect blend of AI assistance and personal control. From patient follow-ups to medication refills, everything is organized by priority in one central hub, ensuring nothing falls through the cracks. Our AI automatically captures tasks from your medical notes, and you can easily add your own tasks on the fly.",
      illustration: (
        <div className="grid grid-cols-2 gap-4 p-4">
          {/* High Priority Task 1 */}
          <div className="bg-white rounded-xl p-4 shadow-sm border border-red-100 hover:scale-105 transition-transform cursor-pointer group relative overflow-hidden">
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-red-400" />
            <div className="flex items-center gap-2 mb-2">
              <button 
                className="w-5 h-5 rounded-full border-2 border-red-400 hover:bg-red-50 transition-colors focus:outline-none group"
                onClick={(e) => {
                  e.currentTarget.classList.toggle('bg-red-400');
                  e.preventDefault();
                }}
              />
              <span className="text-red-400 text-sm">High Priority</span>
            </div>
            <h3 className="text-lg font-semibold mb-1">Order More Vaccines</h3>
            <p className="text-gray-600 text-sm mb-2">Out of rabies vaccines</p>
            <div className="space-y-1 mb-2">
              <p className="text-gray-600 text-sm">• Current stock: 2 doses</p>
              <p className="text-gray-600 text-sm">• Next clinic: Thursday</p>
            </div>
            <p className="text-gray-500 text-sm">Jan 9, 2:45 PM</p>
          </div>

          {/* High Priority Task 2 */}
          <div className="bg-white rounded-xl p-4 shadow-sm border border-red-100 hover:scale-105 transition-transform cursor-pointer group relative overflow-hidden">
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-red-400" />
            <div className="flex items-center gap-2 mb-2">
              <button 
                className="w-5 h-5 rounded-full border-2 border-red-400 hover:bg-red-50 transition-colors focus:outline-none group"
                onClick={(e) => {
                  e.currentTarget.classList.toggle('bg-red-400');
                  e.preventDefault();
                }}
              />
              <span className="text-red-400 text-sm">High Priority</span>
            </div>
            <h3 className="text-lg font-semibold mb-1">Call Specialist</h3>
            <p className="text-gray-600 text-sm mb-2">Patient: Henry</p>
            <div className="space-y-1 mb-2">
              <p className="text-gray-600 text-sm">• Dr. Smith - Neurology</p>
              <p className="text-gray-600 text-sm">• Review MRI results</p>
            </div>
            <p className="text-gray-500 text-sm">Jan 9, 12:56 PM</p>
          </div>

          {/* Medium Priority Task */}
          <div className="bg-white rounded-xl p-4 shadow-sm border border-yellow-100 hover:scale-105 transition-transform cursor-pointer group relative overflow-hidden">
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-yellow-400" />
            <div className="flex items-center gap-2 mb-2">
              <button 
                className="w-5 h-5 rounded-full border-2 border-yellow-400 hover:bg-yellow-50 transition-colors focus:outline-none group"
                onClick={(e) => {
                  e.currentTarget.classList.toggle('bg-yellow-400');
                  e.preventDefault();
                }}
              />
              <span className="text-yellow-400 text-sm">Medium Priority</span>
            </div>
            <h3 className="text-lg font-semibold mb-1">Review Labwork</h3>
            <p className="text-gray-600 text-sm mb-2">Patient: Henry</p>
            <div className="space-y-1 mb-2">
              <p className="text-gray-600 text-sm">• CBC/Chemistry Panel</p>
              <p className="text-gray-600 text-sm">• Compare with Nov 2023</p>
            </div>
            <p className="text-gray-500 text-sm">Jan 9, 12:57 PM</p>
          </div>

          {/* Low Priority Task */}
          <div className="bg-white rounded-xl p-4 shadow-sm border border-green-100 hover:scale-105 transition-transform cursor-pointer group relative overflow-hidden">
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-green-400" />
            <div className="flex items-center gap-2 mb-2">
              <button 
                className="w-5 h-5 rounded-full border-2 border-green-400 hover:bg-green-50 transition-colors focus:outline-none group"
                onClick={(e) => {
                  e.currentTarget.classList.toggle('bg-green-400');
                  e.preventDefault();
                }}
              />
              <span className="text-green-400 text-sm">Low Priority</span>
            </div>
            <h3 className="text-lg font-semibold mb-1">Send Food Samples</h3>
            <p className="text-gray-600 text-sm mb-2">Patient: Indie</p>
            <div className="space-y-1 mb-2">
              <p className="text-gray-600 text-sm">• k/d and i/d samples</p>
              <p className="text-gray-600 text-sm">• Follow up next week</p>
            </div>
            <p className="text-gray-500 text-sm">Jan 9, 2:30 PM</p>
          </div>
        </div>
      ),
      highlights: [
        "AI-powered task detection",
        "Quick manual task creation",
        "Smart priority organization"
      ]
    }
  ];

  return (
    <div id="featurecards" className="w-[90%] max-w-7xl mx-auto px-4 py-24">
      <div className="space-y-32">
        {features.map((feature, index) => (
          <div 
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
            } items-center gap-12`}
          >
            {/* Content Side */}
            <div className="flex-1 space-y-6 flex flex-col justify-center h-full text-center lg:text-left">
              <div className="inline-flex p-3 bg-cosmic-cyan/10 rounded-2xl mx-auto lg:mx-0 w-fit">
                {feature.icon}
              </div>
              <h2 className="text-4xl font-bold text-deep-space">
                {feature.title}
              </h2>
              <p className="text-xl text-gray-600">
                {feature.description}
              </p>
              <ul className="space-y-3">
                {feature.highlights.map((highlight, i) => (
                  <li key={i} className="flex items-center gap-3 justify-center lg:justify-start">
                    <div className="h-1.5 w-1.5 rounded-full bg-cosmic-cyan" />
                    <span className="text-gray-700">{highlight}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Illustration Side */}
            <div className="flex-1 w-full flex items-center justify-center h-full">
              <div className={`w-full rounded-3xl ${
                typeof feature.illustration === 'string' 
                  ? 'bg-gradient-to-br from-cosmic-cyan-light/10 to-deep-space-light/10'
                  : ''
              }`}>
                {typeof feature.illustration === 'string' ? (
                  <img 
                    src={feature.illustration} 
                    alt={feature.title}
                    className="w-full h-full rounded-3xl object-fit"
                  />
                ) : (
                  feature.illustration
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
