import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Navbar } from '../components/navigation/Navbar';
import { Footer } from '../components/navigation/Footer';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';
import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword, sendEmailVerification } from '@firebase/auth';
import { db } from '../firebase/config';
import { doc, setDoc, collection, writeBatch, getDoc } from '@firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51NEDchBQRI88GTVxMlp7NefC69fO61iMpBAbJnmRld7kRMzuXqVrRdaoo0ngAwgHGFRoeHBiYFEZKt7YBJA0jdwW00CUghgQOr'); // Replace with your Stripe publishable key

const CompleteSetupPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  // Get URL parameters and log them
  const clinicId = searchParams.get('clinicId');
  const userId = searchParams.get('userId');
  const email = searchParams.get('email');
  const initialStep = parseInt(searchParams.get('step')) || 1;

  console.log('URL Parameters:', {
    clinicId,
    userId,
    email
  });

  // Update state initialization
  const [step, setStep] = useState(initialStep);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [fadeOut, setFadeOut] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    practiceName: '',
    email: '',
    password: '',
    confirmPassword: '',
    streetAddress: '',
    suite: '',
    city: '',
    postalCode: '',
    veterinarians: 1,
    termsAccepted: false,
    pimsProvider: '',
    promoCode: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // Add effect to fetch clinic data on component mount
  useEffect(() => {
    const fetchClinicData = async () => {
      if (!clinicId) return;

      try {
        const clinicDoc = await getDoc(doc(db, 'clinics', clinicId));
        
        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          
          // Pre-fill form data with existing clinic information
          setFormData(prev => ({
            ...prev,
            streetAddress: clinicData.address?.address || '',
            city: clinicData.address?.city || '',
            postalCode: clinicData.address?.zipcode || '',
            pimsProvider: clinicData.PIMs || '',
            veterinarians: clinicData.numberOfDoctors || 1,
          }));
          
          // Set country and region if they exist
          if (clinicData.address?.country) {
            setCountry(clinicData.address.country);
          }
          if (clinicData.address?.state) {
            setRegion(clinicData.address.state);
          }

          // Store clinicId in localStorage for later use
          localStorage.setItem('clinicId', clinicId);
        }
      } catch (error) {
        console.error('Error fetching clinic data:', error);
        setError(
          <span className="text-red-500 text-xs">
            Error loading clinic data. You can still proceed with setup.
          </span>
        );
      }
    };

    fetchClinicData();
  }, [clinicId]);

  // Add useEffect to get user email from auth
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    console.log('Starting checkout process');
    setError('');
    setLoading(true);

    try {
      if (!email) {
        throw new Error('No email address found');
      }

      // First save the demographic data
      await setDoc(doc(db, 'clinics', clinicId), {
        address: {
          address: formData.streetAddress,
          city: formData.city,
          country: country,
          state: region,
          zipcode: formData.postalCode
        },
        PIMs: formData.pimsProvider,
        numberOfDoctors: formData.veterinarians
      }, { merge: true });

      const subscriptionData = {
        userId,
        clinicId,
        numberOfDoctors: formData.veterinarians,
        billingCycle,
        email: email,
        promoCode: formData.promoCode
      };

      // Log the subscription data being sent
      console.log('Subscription data:', subscriptionData);

      const createCheckoutSession = httpsCallable(getFunctions(), 'createCheckoutSession');
      const result = await createCheckoutSession(subscriptionData);
      
      console.log('Got session result:', result);
      const { sessionId } = result.data;

      await setDoc(doc(db, 'clinics', clinicId), {
        latestCheckoutSession: sessionId
      }, { merge: true });

      console.log('About to redirect to Stripe');
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        throw error;
      }

    } catch (error) {
      console.error('Checkout error:', error);
      setError(
        <span className="text-red-500 text-xs">
          {error.message}
        </span>
      );
      setLoading(false);
    }
  };

  const getPricing = () => {
    const vets = formData.veterinarians;
    
    if (vets >= 7) {
      return (
        <div className="space-y-2">
          <div>Contact us directly for discounted enterprise pricing</div>
          <div className="text-[#00B8D4]">support@lyravet.com</div>
        </div>
      );
    }

    if (vets >= 5) {
      return billingCycle === 'monthly' ? '$595/month' : '$6,800/year';
    }

    if (vets >= 3) {
      return billingCycle === 'monthly' ? '$395/month' : '$4,500/year';
    }

    return billingCycle === 'monthly' ? '$195/month' : '$2,200/year';
  };

  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent">
          <Navbar />
          <main className="max-w-[960px] mx-auto pt-24 pb-24 px-4">
            <div className="bg-white/30 backdrop-blur-md rounded-lg p-6 shadow-xl border border-gray-200/20">
              <div className="text-center mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Complete Your Setup</h1>
                <p className="text-sm text-gray-600 mt-1">Tell us about your practice</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Demographics Section */}
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-800">Practice Info</h2>
                  <form onSubmit={handleCheckout} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Street Address
                      </label>
                      <input
                        type="text"
                        name="streetAddress"
                        value={formData.streetAddress}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter street address"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Country
                        </label>
                        <CountryDropdown
                          value={country}
                          onChange={(val) => setCountry(val)}
                          classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                  focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                  placeholder-gray-400 transition-colors text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          State/Province
                        </label>
                        <RegionDropdown
                          country={country}
                          value={region}
                          onChange={(val) => setRegion(val)}
                          classes="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                  focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                  placeholder-gray-400 transition-colors text-sm"
                          disableWhenEmpty={true}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Enter city"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Postal Code
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                   focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                   placeholder-gray-400 transition-colors text-sm"
                          placeholder="Enter postal code"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        PIMs/EMR Provider
                      </label>
                      <input
                        type="text"
                        name="pimsProvider"
                        value={formData.pimsProvider}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 rounded-lg bg-white/50 border border-gray-200 
                                 focus:border-[#00B8D4] focus:ring-1 focus:ring-[#00B8D4] 
                                 placeholder-gray-400 transition-colors text-sm"
                        placeholder="Enter your EMR/PIMs provider"
                      />
                    </div>
                  </form>
                </div>

                {/* Plan Selection Section */}
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-800">Select Your Plan</h2>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      How many veterinarians will be using LyraVet?
                    </label>
                    <input
                      type="range"
                      min="1"
                      max="7"
                      name="veterinarians"
                      value={formData.veterinarians}
                      onChange={handleInputChange}
                      className="w-full h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-[#00B8D4]"
                    />
                    <div className="flex justify-between text-xs text-gray-600 mt-1">
                      <span>1</span>
                      <span>7+</span>
                    </div>
                    <div className="text-center text-sm text-gray-600 mt-1">
                      Selected: {formData.veterinarians === 7 ? '7+' : formData.veterinarians} veterinarian{formData.veterinarians > 1 ? 's' : ''}
                    </div>
                  </div>

                  <div className="flex justify-center space-x-4 py-2">
                    <button
                      type="button"
                      onClick={() => setBillingCycle('monthly')}
                      className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                ${billingCycle === 'monthly' 
                                  ? 'bg-[#00B8D4] text-white' 
                                  : 'bg-gray-100 text-gray-600'}`}
                    >
                      Monthly
                    </button>
                    <button
                      type="button"
                      onClick={() => setBillingCycle('annual')}
                      className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                                ${billingCycle === 'annual' 
                                  ? 'bg-[#00B8D4] text-white' 
                                  : 'bg-gray-100 text-gray-600'}`}
                    >
                      Annual
                    </button>
                  </div>

                  <div className="text-center py-4">
                    <span className={`${formData.veterinarians >= 7 ? 'text-xl' : 'text-3xl font-bold'} text-gray-800`}>
                      {getPricing()}
                    </span>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={handleCheckout}
                      disabled={loading}
                      className="px-8 bg-[#00B8D4] text-white py-2 rounded-lg 
                               hover:bg-[#008CA1] transition-colors text-sm font-medium mt-0
                               disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {loading ? 'Processing...' : 'Continue to Checkout'}
                    </button>
                  </div>

                  {error && (
                    <p className="text-red-500 text-sm text-center mt-2">
                      {error}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </SpaceBackground>
    </div>
  );
};

export default CompleteSetupPage;
